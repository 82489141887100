
import { Vue, Component, Prop } from 'vue-property-decorator';
import { getAlarmCount, getAlarmList } from '@/api/pushAlarm';
import { getNewAlarmCount } from '@/api/newAlarm';
import { storageKey } from '@/enums/localStorage';
import { getOrderCount } from '@/api/order';
import { getShopDetail } from '@/api/shop';
import { OrderModule } from '@/store/modules/order';
import moment from 'moment';

@Component({
  name: 'SubLayoutHeader',
})
export default class extends Vue {
  // @Prop({ required: true }) private board!: any;

  // @Prop({ required: true }) private newAlarmCount!: any;

  mounted() {
    this.getAlarmList();
    this.getNewAlarmCount();
    if (this.selectedShop === null) {
      this.$message.info('먼저 거점을 선택해주세요.');
      this.$router.push({ name: 'Map2', query: { type: 'station' } });
    }
  }

  private newAlarmCount = 0;

  private showPopover = false;

  private alarmCount = 0;

  private alarmList = [];

  private alarmList2: any[][] = [];

  private totalElements = 0;

  private totalPages = 0;

  private listQuery = {
    page: 0,
    size: 5,
  };

  private selectedShop = JSON.parse((window as any).localStorage.getItem(storageKey.stationPlace));

  private async getAlarmList() {
    await getAlarmList(this.listQuery).then((res) => {
      this.alarmList = res.data.content;
      this.totalElements = res.data.totalElements;
      this.totalPages = res.data.totalPages;
    });
    const itemMap: Map<string, any[]> = new Map();
    this.alarmList.forEach((item: any) => {
      const date: string = item.createDate.substring(0, 10); // 날짜 부분만 추출
      if (!itemMap.has(date)) {
        itemMap.set(date, []);
      }
      const itemList: any[] | undefined = itemMap.get(date);
      if (itemList) {
        itemList.push(item);
      }
    });
    this.alarmList2 = Array.from(itemMap.values());
  }

  private getNewAlarmCount() {
    getNewAlarmCount().then((res) => {
      this.newAlarmCount = res.data;
    });
  }

  private handleClickBefore() {
    if (this.$route.name === 'CustomProduct') {
      this.$router.push({ name: 'Map2', query: { type: 'station' } });
    } else if (this.$route.name === 'CustomProductDetail') {
      this.$router.push({ name: 'CustomProduct' });
    } else if (this.$route.name === 'CustomProductOrder') {
      this.$router.push({ name: 'CustomProductDetail' });
    }
  }

  private handleClickHome() {
      this.$router.push({ name: 'Home' });
  }
}
